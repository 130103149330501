import * as React from "react"
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {CategoryState} from "../reducer/type/category";
import StateInterface from "../reducer/type";
import * as CategoryAction from "../action/category";
import * as SongAction from '../action/song'
import Menu from "../component/Menu";
import Header from "../component/Header";
import ReactJWPlayer from 'react-jw-player';
import {SongState, SongRelateInterface} from "../reducer/type/song";
import LoadingScreen from "../component/LoadingScreen";
import ModalAlert from "../component/ModalAlert";
import ModalDownload from "../component/ModalDownload";
import ModalTime from "../component/ModalTime";
import LoadMore from "../component/LoadMore";
import Player from "../component/Player";
import LazyLoad from 'react-lazyload';
import {Link} from "react-router-dom";
import * as ls from "local-storage";
import * as $ from "jquery";
import {
    ApiLogUser, ApiAddFavorite, ApiRemoveFavorite,
    ApiViewCount
} from "../api/song";
import {
    FacebookShareButton,
    FacebookIcon,

    TwitterShareButton,
    TwitterIcon,

    EmailShareButton,
    EmailIcon,

    PinterestShareButton,
    PinterestIcon
} from 'react-share';
import {HomeState} from "../reducer/type/home";
import * as HomeAction from "../action/home";
import * as AuthAction from "../action/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLink, faPlay
} from '@fortawesome/free-solid-svg-icons'
import VideoItem from '../component/VideoItem'
import {getViewCountSuccess} from "../action/song";
import {AuthState} from "../reducer/type/auth";
import {changeToSlug} from '../helper'
import videojs from "video.js";

interface StatePropsInterface {
    category: CategoryState,
    song: SongState,
    home: HomeState,
    auth: AuthState,
}

interface ParamPropsInterface {
    match: {
        params: {
            id: string
        }
    },
    location: any,
    history: any
}

interface DispatchPropsInterface {
    actions: {
        getCategoryAction: any,
        getSongAction: any,
        autoPlaySongAction: any,
        addFavoriteAction: any,
        removeFavoriteAction: any,
        loadmoreRelateAction: any,
        getInfoAction: any,
        getViewCount: any
    }
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category,
    song: state.song,
    home: state.home,
    auth: state.auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        getCategoryAction: CategoryAction.getCategoryAction,
        getSongAction: SongAction.getSongAction,
        autoPlaySongAction: SongAction.autoPlaySong,
        addFavoriteAction: SongAction.addFavoriteAction,
        removeFavoriteAction: SongAction.removeFavoriteAction,
        loadmoreRelateAction: SongAction.loadmoreRelateAction,
        getInfoAction: AuthAction.getInfoAction,
        getViewCount: SongAction.getViewCountAction
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface & ParamPropsInterface

interface CurrentStateInterface {
    loading: boolean,
    autoplay: number,
    checkAutoPlay: boolean,
    replay: boolean,
    loadmore: boolean,
    checkTime: boolean,
    allowTime: boolean,
    countNext: number,
    player: {
        index: any,
        position: any
    },
    //LAMNV
    currentSongId: any,
    videoJsOptions: any,
    currentSongIndex: number,
    currentLinkStream: string,
    songName: string,
    artist: string,
    countView: string,
    linkDownload: object[]
}

class Play extends React.Component <PropsInterface, CurrentStateInterface> {

    unlisten;

    constructor (props: PropsInterface) {
        super(props);
        this.props.actions.getCategoryAction();
        if (typeof ls.get<string>('token') == 'string') {
            this.props.actions.getInfoAction();
        }
        if (window.location.pathname.indexOf('bang-xep-hang') > -1) {
            this.props.actions.getSongAction(this.props.match.params.id, 'bxh')
        } else {
            this.props.actions.getSongAction(this.props.match.params.id)
        }
        this.state = {
            loading: true,
            autoplay: 0,
            checkAutoPlay: true,
            replay: false,
            loadmore: false,
            checkTime: false,
            allowTime: false,
            countNext: 0,
            player: {
                index: 0,
                position: 0
            },
            //LAMNV
            currentSongId: 0,
            videoJsOptions: undefined,
            currentSongIndex: -1,
            currentLinkStream: "",
            songName: "",
            artist: "",
            countView: "",
            linkDownload: []
        }
        this.unlisten = this.props.history.listen((location) => {
            this.logUser();
        });
        // this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        $('.menu-overlay-2').height($(window).height());
        $('.video-menu-group').on('click', function () {
            $('.video-menu-content').slideDown();
            $('.menu-overlay-2').show();
            $('body').addClass('over-hidden');
        });
        $('.video-menu-close').on('click', function () {
            $('.video-menu-content').slideUp();
            $('.menu-overlay-2').hide();
            $('body').removeClass('over-hidden');
        });
        $('.menu-overlay-2').on('click', function () {
            $('.video-menu-content').slideUp('fast');
            $('.menu-overlay-2').hide();
            $('.favorite-menu-content').css({
                bottom: '-300px'
            });
            $('.share-item-link').tooltip('hide')
            $('body').removeClass('over-hidden');
        });

        $('.input-switch-autoplay').change(function () {
            let data = $('.input-switch-autoplay').prop('checked');
            if (data == true) {
                ls.set<string>('switch', '1');
            } else {
                ls.set<string>('switch', '0');
            }
        });

        let inputSwitch = ls.get<string>('switch');
        // console.log(inputSwitch);
        if (inputSwitch == '1') {
            $('.input-switch-autoplay').prop('checked', true);
        } else if (inputSwitch == '0') {
            $('.input-switch-autoplay').prop('checked', false);
        }

        // $('.modal-alert-login').on('click', function () {
        //     $('.video-menu-content').slideUp('fast');
        //     $('.menu-overlay-2').hide();
        // });

        // console.log($('.player-container'));
        // $('.video-info').css({
        //     height: ($('.player-container').height() + 15) + 'px'
        // });

        // Scroll
        document.addEventListener('scroll', this.trackScrolling, false);
        // document.addEventListener('resize', this.trackScrolling, false);
        // document.addEventListener('orientationchange', this.trackScrolling, false);
    }

    componentWillUnmount() {
        this.unlisten();
        document.removeEventListener('scroll', this.trackScrolling, false);
        // document.removeEventListener('resize', this.trackScrolling, false);
        // document.removeEventListener('orientationchange', this.trackScrolling, false);
    }

    trackScrolling () {
        // const wrappedElement = document.getElementById('container');
        // if ( (wrappedElement.getBoundingClientRect().bottom - 74) <= window.innerHeight ) {
        //     if (typeof this.props.song.data.relate_song.links.next != 'undefined' && this.state.loadmore == false) {
        //         const player = window.jwplayer('video-player');
        //         let data = player.getPlaylistIndex();
        //         let position = player.getPosition();
        //         this.setState({
        //             player: {
        //                 index: data,
        //                 position: position
        //             }
        //         });
        //         this.setState({
        //             loadmore: true
        //         });
        //         this.props.actions.loadmoreRelateAction(this.props.song.data.relate_song.links.next);
        //     }
        // }

        if(window.innerHeight > window.innerWidth){
            if ( $('.player-container').hasClass('player-landscape') ) {
                $('.player-container')
                    .removeClass('player-landscape')
                    .addClass('player-portrait');
                $('.video-info').css({
                    marginTop: ($('.player-container').height() + 15) + 'px'
                });
            }
        } else {
            if ( $('.player-container').hasClass('player-portrait') ) {
                $('.player-container')
                    .removeClass('player-portrait')
                    .addClass('player-landscape');
                $('.video-info').css({
                    marginTop: '10px'
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.song.data !== prevProps.song.data) {
            this.setState({
                loading: false,
                loadmore: false
            });

            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;

            if (!this.state.checkTime) {
                if (this.props.song.data.song_time > 0) {
                    // $('#modal-time').modal('show');
                    this.handleContinueTime();
                }
            }

            if (this.props.song.data.favorite) {
                $('.item-favorite-true').show();
                $('.item-favorite-false').hide();
            } else {
                $('.item-favorite-true').hide();
                $('.item-favorite-false').show();
            }

            if (typeof this.props.song.data.view_count == 'undefined') {
                ApiViewCount(this.props.song.data.id).then(function (response) {
                    if(response.data.code == 200) {
                        $('.video-info-sub-2').text(response.data.data + ' lượt xem')
                    }
                })
            }
        }
        if (this.props.location !== prevProps.location) {
            this.setState({
                loading: true
            });
            if (window.location.pathname.indexOf('bang-xep-hang') > -1) {
                this.props.actions.getSongAction(this.props.match.params.id, 'bxh')
            } else {
                this.props.actions.getSongAction(this.props.match.params.id)
            }
        }
        //==================LAMNV===================
        if (this.props.song.data.link_stream !== prevProps.song.data.link_stream){
            let countView;
            if (this.props.song.data.view_count > 10000) {
                countView = Math.round(this.props.song.data.view_count / 1000) + 'k';
            } else {
                countView = this.props.song.data.view_count;
            }

            this.setState({
                currentSongId: this.props.song.data.id,
                //@ts-ignore
                currentLinkStream: this.props.song.data.link_stream,
                songName: this.props.song.data.name,
                artist: this.props.song.data.artist,
                countView: countView,
                linkDownload: this.props.song.data.link_download
            })
            this.setVideoOptions(this.props.song.data.link_stream, this.state.replay, this.state.checkAutoPlay)
        }
        //==========================================================
    }

    logUser () {
        const player = window.jwplayer('video-player');
        let position = player.getPosition();
        let duration = player.getDuration();
        ApiLogUser(this.props.song.data.id, position, duration);
    }

    onComplete () {

        const player = window.jwplayer('video-player');
        player.stop();
        let data = player.getPlaylistIndex();

        //// let checkReplay = $('.input-replay-video').val().toString();

        if ($('.icon-repeat-true').css("display") == 'none') {
            this.setState({
                player: {
                    index: data,
                    position: 0
                }
            });
            player.on('play');
        }

        let checkAutoplay = $('.input-switch-autoplay').prop('checked');
        if (checkAutoplay) {
            this.setState({
                player: {
                    index: 0,
                    position: 0
                }
            });
            this.props.actions.autoPlaySongAction(
                this.props.song.data.relate_song[0]
            );
            this.setState({
                autoplay: this.state.autoplay + 1
            });
        }
    }

    getElementOffsetTop(el) {
        let boundingClientRect = el.getBoundingClientRect();
        let bodyEl = document.body;
        let docEl = document.documentElement;
        let scrollTop = window.pageYOffset || docEl.scrollTop || bodyEl.scrollTop;
        let clientTop = docEl.clientTop || bodyEl.clientTop || 0;
        return Math.round(boundingClientRect.top + scrollTop - clientTop);
    }

    getScrollTop() {
        let docEl = document.documentElement;
        return (window.pageYOffset || docEl.scrollTop) - (docEl.clientTop || 0);
    }

    handleAddFavorite () {
        if (typeof ls.get<string>('token') == 'string') {
            $('.item-favorite-true').toggle();
            $('.item-favorite-false').toggle();
            let id = $('.input-song-id').val() as string;
            ApiAddFavorite(id);
            // const player = window.jwplayer('video-player');
            // let data = player.getPlaylistIndex();
            // let position = player.getPosition();
            // this.setState({
            //     player: {
            //         index: data,
            //         position: position
            //     }
            // });
            // console.log(this.props.song.data.id);
            // this.props.actions.addFavoriteAction(this.props.song.data.id)
        } else {
            $('.modal-alert-text').text('Bạn cần đăng nhập tài khoản để sử dụng tính năng này!');
            $('#modal-alert').modal('show');
        }
    }

    handleRemoveFavorite () {
        if (typeof ls.get<string>('token') == 'string') {
            $('.item-favorite-true').toggle();
            $('.item-favorite-false').toggle();
            let id = $('.input-song-id').val() as string;
            ApiRemoveFavorite(id);
            // const player = window.jwplayer('video-player');
            // let data = player.getPlaylistIndex();
            // let position = player.getPosition();
            // this.setState({
            //     player: {
            //         index: data,
            //         position: position
            //     }
            // });
            // this.props.actions.removeFavoriteAction(this.props.song.data.id)
        } else {
            $('.modal-alert-text').text('Bạn cần đăng nhập tài khoản để sử dụng tính năng này!');
            $('#modal-alert').modal('show');
        }
    }

    handleInputReplay () {
        // $('.icon-repeat-false').toggle();
        // $('.icon-repeat-true').toggle();
        $('.input-switch-autoplay').prop('checked', false);
        let value = $('.input-replay-video').val() as string;
        $('.input-replay-video').val(
            Math.abs(
                parseFloat(value) - 1
            )
        )
        //===========================LAMNV loop this song======================
        const replay = !this.state.replay
        this.setState({
            checkAutoPlay: false,
            replay: replay
        })
        this.setVideoOptions(this.state.currentLinkStream, replay, this.state.checkAutoPlay)
        //======================================================
    }

    handleDownloadVideo () {
        if (typeof ls.get<string>('token') == 'string') {
            // $('#modal-download').modal('show');
            if (typeof this.props.auth.data.package != "undefined" && this.props.auth.data.package != '') {
                $('#modal-download').modal("show");
            } else {
                $('.modal-alert-text').text('Bạn cần đăng ký gói cước iBolero để sử dụng tính năng này!');
                $('#modal-alert').modal('show');
            }
        } else {
            $('.modal-alert-text').text('Bạn cần đăng nhập và đăng ký gói cước iBolero để sử dụng tính năng này!');
            $('#modal-alert').modal('show');
        }
    }

    handleContinueTime() {
        this.setState({
            player: {
                index: 0,
                position: this.props.song.data.song_time
            }
        })
        $('#modal-time').modal('hide');
    }

    handleShare () {
        $('.menu-overlay-2').show();
        $('.video-menu-content').slideUp();
        $('.favorite-menu-content').css({
            bottom: 0
        });
    }

    handleShareClose () {
        $('.favorite-menu-content').css({
            bottom: '-300px'
        });
        $('.menu-overlay-2').hide();
    }

    handleShareLink () {
        var copyText = document.getElementById("myInput");
        var textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
        $('.share-item-link').tooltip('show')
    }


    //=========LAMNV area==========================
    handleAutoReplay (e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        // @ts-ignore
        const autoPlayChecked:boolean = e.target.checked
        this.setState({
            checkAutoPlay: autoPlayChecked
        })
        if (autoPlayChecked){
            this.setState({
                replay: false
            })
            this.setVideoOptions(this.state.videoJsOptions, false, autoPlayChecked);
        }
        // $('.input-switch-autoplay').prop('checked', false);
        // let value = $('.input-replay-video').val() as string;
        // $('.input-replay-video').val(
        //     Math.abs(
        //         parseFloat(value) - 1
        //     )
        // )

        //===========================LAMNV loop this song======================
        // const replay = !this.state.replay
        // this.setState({
        //     replay: replay
        // })
        // this.setVideoOptions(this.props.song.data.link_stream, replay)
        //======================================================
    }

    setVideoOptions(linkStream, loop, autoPlayNext) {
        const videoJsOptions = {
            autoPlay: true,
            controls: true,
            sources: [{
                src: linkStream,
                type: 'application/x-mpegURL',
                label: "Mặc định"
            }],
            controlBar: {
                pictureInPictureToggle: false
            },
            loop: loop,
            autoPlayNext: autoPlayNext
            // listSong: this.props.song.data.relate_song,
        }
        this.setState({
            videoJsOptions: videoJsOptions
        })
    }

    onEndedVideo() {
        // console.log("onEndedVideo:" + this.state.checkAutoPlay);
        //tu dong phat
        if (this.state.checkAutoPlay){
            const next = this.state.currentSongIndex + 1
            const listSong = this.props.song.data.relate_song
            if (next < listSong.length){
                const player = videojs('iboleroVideo').player();
                player.src([{
                    // @ts-ignore
                    src: listSong[next].link_stream,
                    type: 'application/x-mpegURL'
                }
                ]);
                player.play().then(response => {
                    // console.log(response);
                }).then(error => {
                    // console.log(error);
                })
                //view count
                ApiViewCount(this.state.currentSongId).then(response => {
                    if(response.data.code == 200) {
                        this.setState({
                            countView: response.data.data
                        })
                    }
                })
                //set state
                this.setState({
                    //@ts-ignore
                    currentSongId: listSong[next].id,
                    currentSongIndex: next,
                    //@ts-ignore
                    songName: listSong[next].name,
                    //@ts-ignore
                    artist: listSong[next].artist,
                    //@ts-ignore
                    linkDownload: listSong[next].link_download
                })
            }
        }
    }

    renderProps(obj) {
        if (obj === undefined){
            return;
        }

        Object.entries(obj).map(([key, value]) => (
            console.log(key + ":" + JSON.stringify(value))
        ));
    }
    //=======================================================================
    render() {
        let loadingScreen;
        if (this.state.loading) {
            loadingScreen = (<LoadingScreen/>)
        }

        let playerData;
        //sau khi load xong thi play bai hat
        // if (this.state.videoJsOptions && this.state.videoJsOptions.sources[0].link_stream){
        if (this.props.song.data.link_stream && !this.state.loading) {
            // console.log("this.state.videoJsOptions:" + this.renderProps(this.state.videoJsOptions));

            // const videoJsOptions = {
            //     autoPlay: true,
            //     controls: true,
            //     sources: [{
            //         src: this.props.song.data.link_stream,
            //         type: 'application/x-mpegURL'
            //     }],
            //     controlBar: {
            //         pictureInPictureToggle: false
            //     },
            //     loop: this.state.replay,
            //     listSong: this.props.song.data.relate_song,
            // }

            playerData = (
                <div className="player-container">
                    <div className="player-position" about={this.state.replay.toString()}>
                        {/*<Player {...videoJsOptions} />*/}
                        <Player videoProperties={this.state.videoJsOptions} onEnded={() => this.onEndedVideo()}/>
                    </div>
                </div>
            )
        }

        let listSong;
        listSong = this.props.song.data.relate_song.map((item: SongRelateInterface, key: number) => {
            return (
                <LazyLoad offset={[-100, 100]} height={110} key={key}>
                    <VideoItem item={item}/>
                </LazyLoad>
            );
        });

        let replayView = (this.state.replay) ? (<img src={ require("../asset/images/icon-repeat.png") } style={{
                width: 18
            }}/>) : (<img src={ require("../asset/images/icon-repeat-2.png") } style={{
            width: 18
        }}/>);

        let loadmoreView;
        if (this.state.loadmore) {
            loadmoreView = (<LoadMore/>)
        }

        // let countView;
        // if (this.props.song.data.view_count > 10000) {
        //     countView = Math.round(this.props.song.data.view_count / 1000) + 'k';
        // } else {
        //     countView = this.props.song.data.view_count;
        // }
        //console.log(countView);

        let favoriteViewTrue;
        let favoriteViewFalse;
        if (this.props.song.data.favorite) {
            favoriteViewTrue = (
                <li className="list-group-item item-favorite-true" onClick={this.handleRemoveFavorite}>
                    <img src={ require("../asset/images/icon-heart.png") } style={{
                        width: 18
                    }}/>
                    <input type='hidden' className='input-song-id' value={this.props.song.data.id} />
                    <input type='hidden' className='input-favorite-data' value={this.props.song.data.favorite ? '1' : '0'} />
                    Đã yêu thích
                </li>
            )
            favoriteViewFalse = (
                <li className="list-group-item item-favorite-false" onClick={this.handleAddFavorite} style={{
                    display: "none"
                }}>
                    <img src={ require("../asset/images/icon-heart-2.png") } style={{
                        width: 18
                    }}/>
                    Yêu thích
                </li>
            )
        } else {
            favoriteViewTrue = (
                <li className="list-group-item item-favorite-true" onClick={this.handleRemoveFavorite} style={{
                    display: "none"
                }}>
                    <img src={ require("../asset/images/icon-heart.png") } style={{
                        width: 18
                    }}/>
                    <input type='hidden' className='input-song-id' value={this.props.song.data.id} />
                    <input type='hidden' className='input-favorite-data' value={this.props.song.data.favorite ? '1' : '0'} />
                    Đã yêu thích
                </li>
            )
            favoriteViewFalse = (
                <li className="list-group-item item-favorite-false" onClick={this.handleAddFavorite}>
                    <img src={ require("../asset/images/icon-heart-2.png") } style={{
                        width: 18
                    }}/>
                    Yêu thích
                </li>
            )
        }

        let modalAlert;
        if (typeof ls.get<string>('token') == 'string') {
            modalAlert = (<ModalAlert text='Bạn cần đăng ký gói cước iBolero để sử dụng tính năng này!' button={'package'}/>)
        } else {
            modalAlert = (<ModalAlert text='Bạn cần đăng nhập và đăng ký gói cước iBolero để sử dụng tính năng này!'/>)
        }

        return (
            <div id="container" className="container-fluid overflow-hidden">
                <div className="menu-overlay"/>
                <div className="menu-overlay-2"/>
                <Menu/>
                <Header/>
                {loadingScreen}
                {modalAlert}
                {/*<ModalDownload link={this.props.song.data.link_download} song_id={this.props.song.data.id} />*/}
                <ModalDownload link={this.state.linkDownload} song_id={this.state.currentSongId} />
                <ModalTime time={this.props.song.data.song_time} handleClick={this.handleContinueTime.bind(this)}/>

                <div className="row video-group">
                    <div className="col-12 pr-0 pl-0">
                        { playerData }
                    </div>
                </div>

                <div className="row">
                    <div className="col-11">
                        <div className="video-info-head">
                            {/*{this.props.song.data.name}*/}
                            {this.state.songName}
                        </div>
                        <div className="video-info-sub">
                            {/*{this.props.song.data.artist}*/}
                            {this.state.artist}
                        </div>
                        <div className="video-info-sub-2">
                            {this.state.countView} lượt xem
                        </div>
                    </div>
                    <div className="col-1 pr-0 pl-2 video-menu-parent">
                        <div className="video-menu-group">
                            <img className="video-menu" src={ require("../asset/images/icon-menu-sub.png") }/>
                        </div>
                        <ul className="list-group video-menu-content">
                            <li className="list-group-item" onClick={this.handleShare}>
                                <img src={ require("../asset/images/icon-share.png") } style={{
                                    width: 18
                                }}/>
                                Chia sẻ
                            </li>
                            <li className="list-group-item" onClick={this.handleDownloadVideo.bind(this)}>
                                <img src={ require("../asset/images/icon-download.png") } style={{
                                    width: 18
                                }}/>
                                Tải về máy
                            </li>
                            {favoriteViewTrue}
                            {favoriteViewFalse}
                            {/*<li className="list-group-item">*/}
                            {/*    <img src={ require("../asset/images/icon-ring.png") } style={{*/}
                            {/*        width: 18*/}
                            {/*    }}/>*/}
                            {/*    Tải nhạc chờ*/}
                            {/*</li>*/}
                            <li className="list-group-item" onClick={this.handleInputReplay.bind(this)}>
                                {/*<img className="icon-repeat-false" src={ require("../asset/images/icon-repeat.png") } style={{width: 18, display: 'none'}}/>*/}
                                {/*<img className="icon-repeat-true" src={ require("../asset/images/icon-repeat-2.png") } style={{width: 18}}/>*/}

                                <img className="icon-repeat-true" src={ this.state.replay ? require("../asset/images/icon-repeat.png") : require("../asset/images/icon-repeat-2.png") }
                                     style={{width: 18}}/>

                                <input className="input-replay-video" type="hidden" value='0' />
                                Lặp lại
                            </li>
                            <li className="list-group-item video-menu-close text-center pl-0 pr-0" style={{fontWeight: 600}}>
                                Đóng
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row video-relate">
                    <div className="col-6 pr-0">
                        <div className="video-relate-head">
                            Có thể bạn muốn xem:
                        </div>
                    </div>
                    <div className="col-6">
                        <label className="switch switch-video">
                            <input type="checkbox" className="input-switch-autoplay" onClick={(e) => this.handleAutoReplay(e)}/>
                            <span className="slider round"/>
                        </label>
                        <span className="video-relate-autoplay">Tự động phát</span>
                    </div>
                </div>

                <div className="row video-relate-content">
                    {listSong}
                    {loadmoreView}
                </div>

                <div className="favorite-menu-content">
                    <div className="row favorite-menu-content-1">
                        <div className="col-12 mb-3">
                            <p>Khách hàng sẽ bị mất cước data khi chia sẻ sang mạng xã hội</p>
                            <div className="share-group">
                                <FacebookShareButton
                                    className="share-item"
                                    url={window.location.href}
                                    quote={this.props.song.data.name}
                                    style={{width: '50px'}}>
                                    <FacebookIcon
                                        size={50}
                                        round />
                                </FacebookShareButton>

                                <TwitterShareButton
                                    className="share-item"
                                    url={window.location.href}
                                    title={this.props.song.data.name}
                                    style={{width: '50px'}}>
                                    <TwitterIcon
                                        size={50}
                                        round />
                                </TwitterShareButton>

                                <PinterestShareButton
                                    className="share-item"
                                    url={window.location.href}
                                    media={this.props.song.data.thumbnail_url}
                                    windowWidth={1000}
                                    windowHeight={730}
                                    style={{width: '50px'}}>
                                    <PinterestIcon
                                        size={50}
                                        round />
                                </PinterestShareButton>

                                <EmailShareButton
                                    className="share-item"
                                    url={'ibolero.vn'}
                                    subject={this.props.song.data.name}
                                    body="body"
                                    style={{width: '50px'}}>
                                    <EmailIcon
                                        size={50}
                                        round />
                                </EmailShareButton>

                                <div className="share-item">
                                    <div className="share-item-link" onClick={this.handleShareLink} data-toggle="tooltip" data-placement="top" title="Đã copy">
                                        <FontAwesomeIcon icon={faLink} style={{
                                            color: '#fff'
                                        }} />
                                        <div id="myInput" className="d-none">{window.location.href}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row favorite-menu-content-2">
                        <div className="video-menu-close" onClick={this.handleShareClose}>
                            Đóng
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(Play);
